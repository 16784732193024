import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import api from './utli/api'
import "viewerjs/dist/viewer.css"
import Viewer from "v-viewer"
import VDistpicker from 'v-distpicker'
Vue.use(Viewer)
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)


export default {
  component:{VDistpicker}
}


Vue.use(ElementUI);
Vue.use(BaiduMap, {
	ak: "6pVSdLLnNEBwpgHl9VjcaNqA32Ph6iMg",
});
Vue.config.productionTip = false ;


Vue.prototype.$API = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
