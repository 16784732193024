<template>
  <div id="app" ref="app">
    <router-view />

    <el-backtop target="#app" :bottom="90">
      <!-- right="50"
      visibility-height="0" -->
    </el-backtop>

    <div class="phone">
      <img src="./assets/gototop/phone.png" alt="" />
      <p>联系电话</p>
      <div class="content">
        <p>你好，请拨打24小时电话开启商务洽谈</p>
        <p>东营市旭瑞智能</p>
        <p>0546-8778677</p>
        <p>0546-7773897</p>
        <p>15263802381</p>
        <p>武汉旭瑞创想</p>
        <p>027-59214177</p>
        <p>13797066180</p>
      </div>
    </div>
    <!-- <div class="QQ">
      <img src="./assets/gototop/qq.jpg" alt="" />
      <p>QQ咨询</p>
      <div class="content">
        <img src="./assets/gototop/wechat-QR.jpg" alt="" />
      </div>
    </div> -->
    <div class="wxzx">
      <img src="./assets/gototop/weixin.png" alt="" />
      <p>微信咨询</p>
      <div class="content">
        <img src="./assets/img/dyewm.png" alt="" />
        <p class="p1">山东公司微信客服</p>
        <!-- <p>负责华东、华北市场商务合作、媒体合作、客户服务等</p> -->
        <img src="./assets/img/wxzx.png" style="margin-top: 10px" alt="" />
        <p class="p1">武汉公司微信客服</p>
        <!-- <p>负责华中、华南、西北、西南市场商务合作、媒体合作、客户服务等</p> -->
      </div>
    </div>
    <!-- <div class="zxzx">
      <img src="./assets/gototop/zxzx.jpg" alt="" />
      <p>在线咨询</p>z
      <div class="content">
        <img src="./assets/gototop/wechat-QR.jpg" alt="" />
      </div>
    </div> -->
    <div class="wxgzh">
      <img src="./assets/gototop/wxgzh.png" alt="" />
      <p>微信公众号</p>
      <div class="content">
        <img
          style="width: 120px; height: 120px"
          src="./assets/gototop/公众号.jpg"
          alt=""
        />
        <p style="color: #004aa1; font-size: 20px">官方微信公众号</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    Onlineconsultation() {
      this.dialogVisible = true;
    },
  },
  watch: {
    $route(to, form) {
      let main = this.$refs.app;
      main.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="less">
body {
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  user-select: none;
}
/deep/.el-header {
  box-shadow: 0 0 10px rgb(0 0 0 / 8%);
}

* {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
li {
  list-style: none;
}

.phone,
.QQ,
.wxzx,
.wxgzh,
.zxzx,
.zxzx1 {
  position: absolute;
  right: 40px;
  width: 60px;
  height: 60px;
  background-color: #004aa1;
  text-align: center;
  z-index: 5;
  img {
    width: 20px;
    height: 20px;
    margin-top: 10px;
  }
  p {
    color: white;
    font-size: 12px;
    transform: scale(0.7);
  }
}
.phone {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  bottom: 145px;
  position: absolute;
  img {
    width: 25px;
    height: 25px;
  }
}
.QQ {
  bottom: 205px;
}
.wxzx {
  bottom: 205px;
  img {
    width: 25px;
    height: 25px;
  }
}
.zxzx {
  bottom: 265px;
}
.wxgzh {
  bottom: 265px;
    border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  img {
    height: 25px;
    width: 25px;
  }
}
.zxzx1 {
  bottom: 325px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  img {
    width: 30px;
    height: 30px;
  }
}
.phone {
  .content {
    width: 150px;
    height: 240px;

    position: absolute;
    top: -180px;
    left: -195px;

    width: 0;
    overflow: hidden;
    transform: 0.7s;
    p {
      color: #000;
      font-size: 20px;
    }
    p:nth-child(1) {
      margin-top: 10px;
    }
    p:nth-child(2),
    p:nth-child(6) {
      color: #004aa1;
      font-weight: bold;
    }
    p:nth-child(3),
    p:nth-child(4),
    p:nth-child(5),
    p:nth-child(7),
    p:nth-child(8) {
      text-decoration: underline;
    }
  }
}
.phone:hover .content {
  background: #f9f9f9;
  width: 180px;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
}
.QQ {
  .content {
    width: 130px;
    height: 130px;

    position: absolute;
    top: -60px;
    left: -130px;

    width: 0;
    overflow: hidden;
    transform: 0.7s;
    img {
      width: 120px;
      height: 120px;
      text-align: center;
    }
  }
}
.QQ:hover .content {
  width: 120px;
}
.wxzx {
  .content {
    width: 150px;
    height: 240px;

    position: absolute;
    top: -160px;
    left: -195px;

    display: flex;
    flex-direction: column;

    width: 0;
    overflow: hidden;
    transform: 0.7s;
    img {
      width: 100px;
      height: 100px;
      margin-left: 40px;
    }

    p {
      color: #000;
      font-size: 15px;
      margin-top: -1px;
    }
    .p1 {
      font-weight: bold;
      color: #004aa1;
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: -5px;
    }
  }
}
.wxzx:hover .content {
  background: #f9f9f9;
  width: 180px;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #f9f9f9;
}
.zxzx {
  .content {
    width: 130px;
    height: 130px;

    position: absolute;
    top: -60px;
    left: -130px;

    width: 0;
    overflow: hidden;
    transform: 0.7s;
    img {
      width: 120px;
      height: 120px;
    }
  }
}
.zxzx:hover .content {
  width: 120px;
}
.wxgzh {
  .content {
    width: 240px;

    background-color: #f9f9f9;

    position: absolute;
    top: -60px;
    left: -155px;

    width: 0;
    overflow: hidden;
    transform: 0.7s;
  }
}

.wxgzh:hover .content {
  width: 140px;
  height: 180px;
  border-radius: 5px;
  font-weight: 700;
  // height: 240px;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
