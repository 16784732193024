
    /***
     *
     * promise 二次封装请求
     */
     import axios from "axios";
     // 一些请求的基本配置
     const service = axios.create({
      //  baseURL: "http://101.200.43.114:8714", // 正式
      baseURL: "https://suree.xuruidea.com/api", // 正式
      //  baseURL: "http://192.168.0.37:8714", // 刘
       headers: {
        //  "Content-Type": "application/x-www-form-urlencoded;charset=utf-8" // fromData格式参数
       }
     });

     // 输出方法
     export default function request(url, data = {},method="post") {  // 如果此处的method为get 则需要传入get，默认为post
      // console.log('data :>> ', data);
       return new Promise((resolve, reject) => {
         const options = {
           url,
           method
         };
         if (method.toLowerCase() === "get") {
           options.params = data;		//如果为get传参为params
         } else {
           options.data = data;   //  post等 传数据为data
         }
         service(options)
           .then(res => {
             // console.log("返回数据", res);
             resolve(res.data);   //返回数据
           })
           .catch(error => {
             reject();
             console.error(error);  //异常捕获
           });
         });
     }
