import request from "./request";
const api = {
  // 导航栏
  getNavigationList(data) {
    return request("/website/selectInfoTypesList/commonTapTree", data, "get");
  },
  // footer数据
  getFooterData(data) {
    return request("/website/selectLinksListCommonTapTree", data, "get");
  },
  // 轮播图
  getBanner(data) {
    return request("/website/selectInfoTypeImgsList", data, "get");
  },
  // 硬件产品
  getHardware(data) {
    return request("/website/selectCmpDeviceList", data, "get");
  },
  // 硬件分页
  getFenYeHardware(data) {
    return request("/website/selectCmpDeviceListPage", data, "get");
  },
  // 硬件详情
  getHardwareDetail(data) {
    return request("/website/selectCmpDeviceById", data, "get");
  },
  // 软件产品
  getSoftware(data) {
    return request("/website/selectCmpProjectList", data, "get");
  },
  //   软件分页
  getFenYeSoftware(data) {
    return request("/website/selectCmpProjectListPage", data, "get");
  },
  //   软件详情
  getSoftwareDetail(data) {
    return request("/website/selectCmpProjectById", data, "get");
  },

  // 全部新闻
  getNewsListAll(data) {
    return request("/website/selectCompanyNewsListAll", data, "get");
  },
  // 新闻列表
  getNewsList(data) {
    return request("/website/selectCompanyNewsList", data, "get");
  },
  getFenNewsList(data) {
    return request("/website/selectCompanyNewsListPage", data, "get");
  },
  // 获取详情
  getNewsDetails(data) {
    return request("/website/selectCompanyNewsById", data, "get");
  },
  // 公司信息
  getCompanyInfor(data) {
    return request("/website/selectCompanyList", data, "get");
  },
  // 文章列表
  getArticleList(data) {
    return request("/website/selectArticlesList", data, "get");
  },
  // 全部新闻
  getArticleListAll(data) {
    return request("/website/selectCompanyNewsList", data, "get");
  },
  
  // 留言
  addMessage(data) {
    return request("/website/insertFeedbacks", data);
  },
};

export default api;
