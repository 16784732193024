import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [{
      path: '/',
      name: 'index',
      component: () => import('../views/index.vue'),

    }, {
      path: '/product',
      name: 'product',
      component: () => import('../views/product.vue'),
    }, {
      path: '/programme',
      name: 'programme',
      component: () => import('../views/programme.vue'),

    }, {
      path: '/ServiceSupport',
      name: 'ServiceSupport',
      component: () => import('../views/ServiceSupport.vue'),
    }, {
      path: '/case',
      name: 'case',
      component: () => import('../views/case.vue'),
    }, {
      path: '/about',
      name: 'about',
      component: () => import('../views/about.vue'),
    }, {
      path: '/news',
      name: 'news',
      component: () => import('../views/news.vue'),
    }, {
      path: '/marketNews',
      name: 'marketNews',
      component: () => import('../views/marketNews.vue'),
    }, {
      path: '/professionNews',
      name: 'professionNews',
      component: () => import('../views/professionNews.vue'),
    }, {
      path: '/companyNews',
      name: 'companyNews',
      component: () => import('../views/companyNews.vue'),
    }, {
      path: '/productNews',
      name: 'productNews',
      component: () => import('../views/productNews.vue'),
    }, {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/contact.vue'),
    }, {
      path: '/productDetails',
      name: 'productDetails',
      component: () => import('../views/productDetails.vue'),
    }, {
      path: '/ProgrammeDetails',
      name: 'ProgrammeDetails',
      component: () => import('../views/ProgrammeDetails.vue'),
    }, {
      path: '/NewsDetail/:id',
      name: 'NewsDetail',
      component: () => import('../views/NewsDetail.vue'),
    }, {
      path: '/HardwareDetail/:id',
      name: 'HardwareDetail',
      component: () => import('../views/HardwareDetail.vue'),
    }, {
      path: '/SoftwareDetail/:id/:Type',
      name: 'SoftwareDetail',
      component: () => import('../views/SoftwareDetail.vue'),
    }, {
      path: '/merchants',
      name: 'merchants',
      component: () => import('../views/merchants.vue'),
    },
    {
      path: '/hotarticle',
      name: 'hotarticle',
      component: () => import('../views/hotarticle.vue'),
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('../views/search.vue'),
    }
    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
export default router
