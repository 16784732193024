import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    num: 0,   //智慧跳转
    metaInfo: {},
    newData: "",
    hot: []
  },
  mutations: {
    setNum(state, payload) {   // 参数
      state.num = payload
    },
    setNewData(state, payload) {
      state.newData = payload
    },
    CAHNGE_META_INFO(state, metaInfo) {
      console.log(metaInfo, "metaInfo")
      state.metaInfo = metaInfo;
    },
    setHot(state, payload) {
      state.hot = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
